@import "~antd/dist/antd.less";

// @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

.ant-btn-default {
  background-color: #cc0000;
  border-radius: 4px;
  color: white;
  border: 1px solid #cc0000;
}

.ant-btn-default:hover {
  color: white;
  border: 1px solid #cc000094;
  background-color: #cc000094;
}

html {
  scroll-behavior: smooth;
}

.ant-slider-rail {
  height: 18px;
}

.ant-slider-track {
  height: 18px;
  // background: #3858a7;
  // background: linear-gradient(
  //   90deg,
  //   rgba(176, 54, 54, 1) 23%,
  //   rgba(255, 213, 0, 1) 55%,
  //   rgba(40, 255, 0, 1) 100%
  // );
  background: linear-gradient(
    90deg,
    rgba(201, 206, 232, 1) 0%,
    rgba(131, 155, 218, 1) 21%,
    rgba(81, 108, 178, 1) 51%,
    rgba(56, 88, 167, 1) 100%
  );
}

.ant-slider-dot {
  width: 22px;
  height: 22px;
  display: none;
}
.ant-slider-handle {
  width: 25px;
  height: 25px;
  padding-left: 5px;
  margin-left: 5px;
}

.ant-slider-mark-text {
  margin-top: 10px;
  margin-left: 5px;
}

.repeater-items-space,
.repeater-items-space .ant-space-item:first-child {
  width: 100%;
}

.rating-container {
  padding: 0;
}

@media screen and (max-width: 380px) {
  .emoji-rating .flat-face > div:nth-last-child(-n + 2),
  .emoji-rating .shock-face > div:nth-last-child(-n + 2),
  .emoji-rating .smile-face > div:nth-last-child(-n + 2),
  .emoji-rating .happy-face > div:nth-last-child(-n + 2),
  .emoji-rating .sad-face > div:nth-last-child(-n + 2) {
    transform: scale(0.8);
  }

  .emoji-rating .canRate > div:nth-last-child(-n + 3) {
    transform: scale(0.8);
  }

  .emoji-rating {
    max-width: 240px;
  }

  .emoji-rating .label {
    font-size: 14px !important;
  }
  .input-activities-selections .image-checkbox-container h4 {
    font-size: 10px !important;
  }
}

.select-learning-activity .ant-checkbox-inner {
  border-color: #415fab;
}

.editorClassName {
  min-height: 230px !important;
  max-height: 230px !important;
}

.tox-notification.tox-notification--in.tox-notification--warning {
  display: none !important;
}

.loader-container,
.flex-middle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.you-container {
  text-align: center;
  cursor: pointer;
}

@primary-color: #415FAB;