.logo-desc > h2 {
  margin: 0;
}

.selections-sidebar-panel .ant-card-head-title {
  display: flex;
}

.selections-sidebar-card .ant-card-head {
  padding-left: 0;
}
